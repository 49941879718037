
import React, { useEffect , useState}  from 'react';
import { NavLink } from 'react-router-dom';
import './header.sass';
import VideoYoutube from "./video-youtube";
import {Authenticator} from "@aws-amplify/ui-react";
import {useTranslation} from "react-i18next";
import { Hub, Auth } from 'aws-amplify';


const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

function Header() {
    const [t, i18n] = useTranslation('default');
    const [user, setUser] = useState(null);

    useEffect(() => {
        const updateUser = async () => {
          try {
            const currentUser = await Auth.currentAuthenticatedUser();
            setUser(currentUser);
          } catch (error) {
            setUser(null);
          }
        };
    
        Hub.listen('auth', ({ payload: { event } }) => {
          if (event === 'signIn' || event === 'signOut') {
            updateUser();
          }
        });
    
        updateUser();
      }, []);
    
      const signOut = async () => {
        try {
          await Auth.signOut();
        } catch (error) {
          console.error('Error signing out:', error);
        }
      };
    return (

        <header>
        <div class="content">
            <svg xmlns="http://www.w3.org/2000/svg" width="136.697" height="23.239" viewBox="0 0 136.697 23.239">
                <g id="logoTech" transform="translate(-70.121 5)">
                <g id="Grupo_4" data-name="Grupo 4" transform="translate(70.121 -5)">
                    <path id="Trazado_18" data-name="Trazado 18" d="M537.626,469.807a3.564,3.564,0,1,0-3.564,3.564,3.564,3.564,0,0,0,3.564-3.564" transform="translate(-530.498 -466.243)" fill="#06f"/>
                    <path id="Trazado_19" data-name="Trazado 19" d="M589.511,469.807a3.564,3.564,0,1,0-3.564,3.564,3.564,3.564,0,0,0,3.564-3.564" transform="translate(-574.008 -466.243)" fill="#06f"/>
                    <path id="Trazado_20" data-name="Trazado 20" d="M641.4,469.807a3.564,3.564,0,1,0-3.564,3.564,3.564,3.564,0,0,0,3.564-3.564" transform="translate(-617.519 -466.243)" fill="#06f"/>
                    <path id="Trazado_21" data-name="Trazado 21" d="M589.511,521.693a3.564,3.564,0,1,0-3.564,3.564,3.564,3.564,0,0,0,3.564-3.564" transform="translate(-574.008 -510.074)" fill="#06f"/>
                    <path id="Trazado_22" data-name="Trazado 22" d="M589.511,573.579a3.564,3.564,0,1,0-3.564,3.564,3.564,3.564,0,0,0,3.564-3.564" transform="translate(-574.008 -553.904)" fill="#06f"/>
                    <path id="Trazado_23" data-name="Trazado 23" d="M708.236,512.73h-3.321v-1.977h8.7v1.977h-3.321v9.094h-2.056Z" transform="translate(-676.915 -503.928)" fill="#06f"/>
                    <path id="Trazado_24" data-name="Trazado 24" d="M761.14,534.241a3.764,3.764,0,0,1-3.717,2.531,4.192,4.192,0,0,1,0-8.382,3.934,3.934,0,0,1,3.954,4.033,4.53,4.53,0,0,1-.047.57l-.032.221h-6.01a2.126,2.126,0,0,0,2.135,1.9,1.844,1.844,0,0,0,1.661-.87Zm-1.74-2.372a1.982,1.982,0,0,0-1.977-1.819,2.126,2.126,0,0,0-2.135,1.819Z" transform="translate(-717.541 -518.772)" fill="#06f"/>
                    <rect id="Rectángulo_26" data-name="Rectángulo 26" width="1.977" height="11.071" transform="translate(44.982 6.826)" fill="#06f"/>
                    <path id="Trazado_25" data-name="Trazado 25" d="M838.04,534.241a3.764,3.764,0,0,1-3.717,2.531,4.192,4.192,0,0,1,0-8.382,3.934,3.934,0,0,1,3.954,4.033,4.477,4.477,0,0,1-.048.57l-.031.221h-6.01a2.126,2.126,0,0,0,2.135,1.9,1.844,1.844,0,0,0,1.661-.87Zm-1.74-2.372a1.982,1.982,0,0,0-1.977-1.819,2.126,2.126,0,0,0-2.135,1.819Z" transform="translate(-782.096 -518.772)" fill="#06f"/>
                    <path id="Trazado_26" data-name="Trazado 26" d="M884.458,514.834h-1.344v-1.9h1.344V511.75a1.852,1.852,0,0,1,1.977-1.977h1.74v1.74h-1.186a.54.54,0,0,0-.554.553v.87h1.74v1.9h-1.74V521h-1.977Z" transform="translate(-826.499 -503.102)" fill="#06f"/>
                    <path id="Trazado_27" data-name="Trazado 27" d="M924.523,532.581a4.113,4.113,0,1,1-4.112-4.191,4.009,4.009,0,0,1,4.112,4.191m-1.977,0a2.147,2.147,0,1,0-2.135,2.372,2.191,2.191,0,0,0,2.135-2.372" transform="translate(-854.364 -518.773)" fill="#06f"/>
                    <path id="Trazado_28" data-name="Trazado 28" d="M974.59,528.548h1.819l.158.791h.079a2.626,2.626,0,0,1,.538-.474,3.021,3.021,0,0,1,1.677-.475,3.14,3.14,0,0,1,3.163,3.4v4.824h-1.977v-4.665a1.74,1.74,0,0,0-3.48,0v4.665H974.59Z" transform="translate(-903.295 -518.768)" fill="#06f"/>
                    <path id="Trazado_29" data-name="Trazado 29" d="M1057.513,533.609a3.74,3.74,0,0,1-3.8,3.163,4.192,4.192,0,0,1,0-8.382,3.748,3.748,0,0,1,3.8,3.084h-1.977a1.842,1.842,0,0,0-1.819-1.265,2.385,2.385,0,0,0,0,4.745,1.792,1.792,0,0,0,1.819-1.344Z" transform="translate(-966.268 -518.773)" fill="#06f"/>
                    <path id="Trazado_30" data-name="Trazado 30" d="M1108.1,535.822h-.079a2.357,2.357,0,0,1-.553.475,3.177,3.177,0,0,1-1.74.474c-1.834,0-2.926-1.123-2.926-2.451,0-1.582,1.107-2.689,3.321-2.689h1.819v-.158a1.375,1.375,0,0,0-1.424-1.5A1.351,1.351,0,0,0,1105.1,531h-1.977a3.1,3.1,0,0,1,3.4-2.61,3.134,3.134,0,0,1,3.4,3.084v5.14h-1.66Zm-.158-2.688h-1.661c-1.028,0-1.5.4-1.5,1.028s.459,1.028,1.265,1.028a1.71,1.71,0,0,0,1.9-1.819Z" transform="translate(-1010.922 -518.772)" fill="#06f"/>
                    <rect id="Rectángulo_27" data-name="Rectángulo 27" width="1.977" height="8.066" transform="translate(80.211 9.778)" fill="#06f"/>
                    <path id="Trazado_31" data-name="Trazado 31" d="M1031.232,508.861a1.17,1.17,0,1,0-1.17,1.17,1.17,1.17,0,0,0,1.17-1.17" transform="translate(-948.862 -501.199)" fill="#06f"/>
                    <path id="Trazado_32" data-name="Trazado 32" d="M938.377,508.3h2.056l-1.9,2.293h-1.661Z" transform="translate(-871.624 -501.716)" fill="#06f"/>
                    <path id="Trazado_33" data-name="Trazado 33" d="M1179.66,512.737h-3.322V510.76h8.7v1.977h-3.321v9.095h-2.056Z" transform="translate(-1072.659 -503.933)" fill="#06f"/>
                    <path id="Trazado_34" data-name="Trazado 34" d="M1232.575,534.25a3.764,3.764,0,0,1-3.717,2.531,4.192,4.192,0,0,1,0-8.383,3.934,3.934,0,0,1,3.954,4.033,4.508,4.508,0,0,1-.047.569l-.032.221h-6.011a2.127,2.127,0,0,0,2.136,1.9,1.844,1.844,0,0,0,1.661-.87Zm-1.74-2.373a1.982,1.982,0,0,0-1.977-1.819,2.127,2.127,0,0,0-2.136,1.819Z" transform="translate(-1113.387 -518.779)" fill="#06f"/>
                    <path id="Trazado_35" data-name="Trazado 35" d="M1287.725,533.618a3.74,3.74,0,0,1-3.8,3.163,4.192,4.192,0,0,1,0-8.383,3.748,3.748,0,0,1,3.8,3.084h-1.977a1.842,1.842,0,0,0-1.819-1.265,2.386,2.386,0,0,0,0,4.745,1.793,1.793,0,0,0,1.819-1.344Z" transform="translate(-1159.569 -518.779)" fill="#06f"/>
                    <path id="Trazado_36" data-name="Trazado 36" d="M1335.669,510.76h1.977v3.8h.079a2.61,2.61,0,0,1,.538-.475,3.021,3.021,0,0,1,1.677-.475,3.141,3.141,0,0,1,3.164,3.4v4.824h-1.977v-4.666a1.74,1.74,0,0,0-3.48,0v4.666h-1.977Z" transform="translate(-1206.405 -503.933)" fill="#06f"/>
                </g>
                </g>
            </svg>
            <div class="languages-container">
                <div class="language">
                    <NavLink className="first" to="/">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                            <g id="Grupo_6525" data-name="Grupo 6525" transform="translate(-1121 -26)">
                                <g id="idioma" transform="translate(528 -12)">
                                    <text id="ES" transform="translate(599 55)" font-size="11" font-family="TelefonicaLight" letter-spacing="0.1em"><tspan x="0" y="0">ES</tspan></text>
                                </g>
                                <g id="Elipse_169" data-name="Elipse 169" transform="translate(1121 26)" fill="none" stroke-width="1" opacity="0.999">
                                    <circle cx="13" cy="13" r="13" stroke="none"></circle>
                                    <circle cx="13" cy="13" r="12.5" fill="none"></circle>
                                </g>
                            </g>
                        </svg>
                    </NavLink>
        
                    <NavLink className="second" to="/en">
                        <svg width="26" height="26" viewBox="0 0 26 26">
                            <g id="Grupo_6525" data-name="Grupo 6525" transform="translate(-1121 -26)">
                                <g id="idioma" transform="translate(528 -12)">
                                    <text id="EN" transform="translate(598 55)" font-size="11" font-family="TelefonicaLight" letter-spacing="0.1em"><tspan x="0" y="0">EN</tspan></text>
                                </g>
                                <g id="Elipse_169" data-name="Elipse 169" transform="translate(1121 26)" fill="none" stroke-width="1" opacity="0.999">
                                    <circle cx="13" cy="13" r="12.5" fill="none"></circle>
                                </g>
                            </g>
                        </svg>
                    </NavLink>

                    {user && (
                        <div className="logoutBtt" onClick={signOut} title={t('Logout')}>
                            <svg height="20" viewBox="0 0 490.3 490.3" width="20" xmlns="http://www.w3.org/2000/svg"><path d="m0 121.05v248.2c0 34.2 27.9 62.1 62.1 62.1h200.6c34.2 0 62.1-27.9 62.1-62.1v-40.2c0-6.8-5.5-12.3-12.3-12.3s-12.3 5.5-12.3 12.3v40.2c0 20.7-16.9 37.6-37.6 37.6h-200.5c-20.7 0-37.6-16.9-37.6-37.6v-248.2c0-20.7 16.9-37.6 37.6-37.6h200.6c20.7 0 37.6 16.9 37.6 37.6v40.2c0 6.8 5.5 12.3 12.3 12.3s12.3-5.5 12.3-12.3v-40.2c0-34.2-27.9-62.1-62.1-62.1h-200.7c-34.2 0-62.1 27.8-62.1 62.1z"/><path d="m385.4 337.65c2.4 2.4 5.5 3.6 8.7 3.6s6.3-1.2 8.7-3.6l83.9-83.9c4.8-4.8 4.8-12.5 0-17.3l-83.9-83.9c-4.8-4.8-12.5-4.8-17.3 0s-4.8 12.5 0 17.3l63 63h-229.9c-6.8 0-12.3 5.5-12.3 12.3s5.5 12.3 12.3 12.3h229.8l-63 63c-4.8 4.7-4.8 12.5 0 17.2z"/></svg>
                        </div>

                    )}
           
                </div>
            </div>     
        </div>

        </header>
    );
  }
  
export default Header;




