
import './logo.sass';
import title from "../assets/title.png";

function Logo() {

    return (
        <div className="logo-container">
            <img className="logo-title" src={title} alt=""></img>
        </div>
    );
  }
  
export default Logo;




