import Header from '../components/header';
import VideoYoutube from '../components/video-youtube';
import VideoVimeo from '../components/video-vimeo';
import VideoCoomingsoon from '../components/video-coomingsoon';
import Footer from '../components/footer';
import Logo from '../components/logo';
import React, {useEffect, useState} from 'react';
import {Authenticator, translations} from '@aws-amplify/ui-react';
import {Amplify, Auth, Hub, I18n as awsI18n} from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import {useTranslation} from "react-i18next";
import awsConfig from '../aws-exports';
import translations_es from "../translations/es.json"
import translations_en from "../translations/en.json"
import appData from "../app-data.json";
import Helmet from "react-helmet";


Amplify.configure(awsConfig)

const combinedTranslations = {"en": translations_en, "es": translations_es};

awsI18n.putVocabularies(translations);
awsI18n.putVocabularies(combinedTranslations);

function Index(props) {
    const [t, i18n] = useTranslation('default');

    awsI18n.setLanguage(props.lang);
    const [user, setUser] = useState(null);

    useEffect(() => {

        i18n.changeLanguage(props.lang);

        const updateUser = async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser();
                setUser(currentUser);
            } catch (error) {
                setUser(null);
            }
        };

        Hub.listen('auth', ({payload: {event}}) => {
            if (event === 'signIn' || event === 'signOut') {
                updateUser();
            }
        });

        updateUser();
    }, [props.lang]);

    let ContentComponent;

    if(process.env.REACT_APP_MODE_EXE === 'youtube'){
        ContentComponent = <VideoYoutube VideoID={appData.YoutubeVideoID[props.lang]} Language={props.lang} />
    }else if (process.env.REACT_APP_MODE_EXE === 'vimeo'){
        ContentComponent = <VideoVimeo VideoID={appData.VimeoVideoID[props.lang]} Language={props.lang} />
    }else{
        ContentComponent = <VideoCoomingsoon />
    }

    let FinalComponent;

    // Set AutoComplete OFF in formfields
    const formFields = {
        signUp: { email: {autocomplete: "off"}, password: {autocomplete: "off"}, confirm_password: {autocomplete: "off"} },
        signIn: { username: {autocomplete: "off"}, password: {autocomplete: "off"} },
        resetPassword: { username: {autocomplete: "off"} },
        confirmResetPassword:{ confirmation_code: {autocomplete: "off"}, password: {autocomplete: "off"}, confirm_password: {autocomplete: "off"} }
    }

    if (typeof process.env.REACT_APP_ENABLE_AUTH !== 'undefined' && process.env.REACT_APP_ENABLE_AUTH === 'false') {
        FinalComponent = ContentComponent
    }else{
        FinalComponent = <Authenticator formFields={formFields} key={props.lang}> {ContentComponent} </Authenticator>
    }

    return (
        <div>
            <Helmet>
                <html lang={props.lang} />
                <title>{t('General Title')}</title>
            </Helmet>
            <Header/>
            <main>
                <div>
                    <Logo/>
                    <div className="content">
                        {FinalComponent}
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    );
}

export default Index;