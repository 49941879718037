import React from 'react';
import './video-coomingsoon.sass';
import {useTranslation} from "react-i18next";

function VideoComingsoon(props) {
    const [t, i18n] = useTranslation('default');

    return (
        <div id="video-commingsoon">
            <div className="message-container">
                <div className="message" dangerouslySetInnerHTML={{ __html: t('Video Comming Soon') }} />
            </div>
        </div>
    );
  }
  
export default VideoComingsoon;