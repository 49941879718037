import React from 'react';
import { useTranslation } from 'react-i18next';
import './footer.sass';

function Footer() {
    const [t, i18n] = useTranslation('default');
    const openConsentDialog = () => {

        if (window.OneTrust) {
            window.OneTrust.ToggleInfoDisplay();
        }
    };
    
    return (

        <footer>
        <div class="content">
            <a href="https://telefonicatech.com" rel="noopener noreferrer" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" width="158.576" height="26.958" viewBox="0 0 158.576 26.958">
                    <g id="Grupo_7465" data-name="Grupo 7465" transform="translate(0 26)">
                    <path id="Trazado_18" data-name="Trazado 18" d="M538.767,470.377a4.134,4.134,0,1,0-4.134,4.134,4.134,4.134,0,0,0,4.134-4.134" transform="translate(-530.498 -492.243)" fill="#f2f4ff"/>
                    <path id="Trazado_19" data-name="Trazado 19" d="M590.652,470.377a4.134,4.134,0,1,0-4.135,4.134,4.135,4.135,0,0,0,4.135-4.134" transform="translate(-572.667 -492.243)" fill="#f2f4ff"/>
                    <path id="Trazado_20" data-name="Trazado 20" d="M642.538,470.377a4.134,4.134,0,1,0-4.134,4.134,4.134,4.134,0,0,0,4.134-4.134" transform="translate(-614.838 -492.243)" fill="#f2f4ff"/>
                    <path id="Trazado_21" data-name="Trazado 21" d="M590.652,522.263a4.134,4.134,0,1,0-4.135,4.134,4.135,4.135,0,0,0,4.135-4.134" transform="translate(-572.667 -534.784)" fill="#f2f4ff"/>
                    <path id="Trazado_22" data-name="Trazado 22" d="M590.652,574.149a4.134,4.134,0,1,0-4.135,4.134,4.134,4.134,0,0,0,4.135-4.134" transform="translate(-572.667 -577.326)" fill="#f2f4ff"/>
                    <path id="Trazado_23" data-name="Trazado 23" d="M708.768,513.047h-3.853v-2.293h10.091v2.293h-3.853V523.6h-2.385Z" transform="translate(-672.434 -528.835)" fill="#f2f4ff"/>
                    <path id="Trazado_24" data-name="Trazado 24" d="M762.393,535.177a4.366,4.366,0,0,1-4.312,2.936,4.863,4.863,0,0,1,0-9.724,4.564,4.564,0,0,1,4.587,4.679,5.263,5.263,0,0,1-.055.661l-.037.257H755.6a2.467,2.467,0,0,0,2.477,2.2,2.139,2.139,0,0,0,1.926-1.009Zm-2.018-2.752a2.3,2.3,0,0,0-2.293-2.11,2.466,2.466,0,0,0-2.477,2.11Z" transform="translate(-711.816 -543.232)" fill="#f2f4ff"/>
                    <rect id="Rectángulo_26" data-name="Rectángulo 26" width="2.293" height="12.843" transform="translate(52.181 -18.081)" fill="#f2f4ff"/>
                    <path id="Trazado_25" data-name="Trazado 25" d="M839.292,535.177a4.366,4.366,0,0,1-4.312,2.936,4.863,4.863,0,0,1,0-9.724,4.564,4.564,0,0,1,4.587,4.679,5.2,5.2,0,0,1-.055.661l-.037.257H832.5a2.467,2.467,0,0,0,2.477,2.2,2.139,2.139,0,0,0,1.927-1.009Zm-2.018-2.752a2.3,2.3,0,0,0-2.293-2.11,2.466,2.466,0,0,0-2.477,2.11Z" transform="translate(-774.395 -543.232)" fill="#f2f4ff"/>
                    <path id="Trazado_26" data-name="Trazado 26" d="M884.673,515.644h-1.559v-2.2h1.559v-1.376a2.148,2.148,0,0,1,2.293-2.293h2.018v2.018h-1.376a.627.627,0,0,0-.642.642v1.009h2.018v2.2h-2.018V522.8h-2.293Z" transform="translate(-817.437 -528.034)" fill="#f2f4ff"/>
                    <path id="Trazado_27" data-name="Trazado 27" d="M925.84,533.252a4.771,4.771,0,1,1-4.77-4.862,4.65,4.65,0,0,1,4.77,4.862m-2.293,0A2.491,2.491,0,1,0,921.069,536a2.541,2.541,0,0,0,2.477-2.752" transform="translate(-844.451 -543.233)" fill="#f2f4ff"/>
                    <path id="Trazado_28" data-name="Trazado 28" d="M974.59,528.573h2.11l.183.917h.092a3.045,3.045,0,0,1,.624-.55,3.505,3.505,0,0,1,1.945-.551,3.643,3.643,0,0,1,3.669,3.945v5.6H980.92v-5.412a2.018,2.018,0,0,0-4.036,0v5.412H974.59Z" transform="translate(-891.884 -543.228)" fill="#f2f4ff"/>
                    <path id="Trazado_29" data-name="Trazado 29" d="M1058.778,534.444a4.338,4.338,0,0,1-4.4,3.669,4.863,4.863,0,0,1,0-9.724,4.348,4.348,0,0,1,4.4,3.578h-2.293a2.137,2.137,0,0,0-2.11-1.468,2.767,2.767,0,0,0,0,5.5,2.079,2.079,0,0,0,2.11-1.559Z" transform="translate(-952.93 -543.233)" fill="#f2f4ff"/>
                    <path id="Trazado_30" data-name="Trazado 30" d="M1108.948,537.012h-.092a2.734,2.734,0,0,1-.642.551,3.686,3.686,0,0,1-2.018.55c-2.128,0-3.394-1.3-3.394-2.844,0-1.835,1.284-3.119,3.853-3.119h2.11v-.183a1.6,1.6,0,0,0-1.651-1.743,1.567,1.567,0,0,0-1.651,1.193h-2.293c.2-1.541,1.449-3.027,3.945-3.027a3.636,3.636,0,0,1,3.944,3.578v5.963h-1.926Zm-.183-3.119h-1.927c-1.193,0-1.743.458-1.743,1.192s.532,1.193,1.468,1.193a1.984,1.984,0,0,0,2.2-2.11Z" transform="translate(-996.216 -543.232)" fill="#f2f4ff"/>
                    <rect id="Rectángulo_27" data-name="Rectángulo 27" width="2.293" height="9.357" transform="translate(93.049 -14.658)" fill="#f2f4ff"/>
                    <path id="Trazado_31" data-name="Trazado 31" d="M1031.607,509.048a1.357,1.357,0,1,0-1.357,1.357,1.357,1.357,0,0,0,1.357-1.357" transform="translate(-936.054 -526.16)" fill="#f2f4ff"/>
                    <path id="Trazado_32" data-name="Trazado 32" d="M938.617,508.3H941l-2.2,2.66h-1.927Z" transform="translate(-861.18 -526.662)" fill="#f2f4ff"/>
                    <path id="Trazado_33" data-name="Trazado 33" d="M1180.191,513.054h-3.853V510.76h10.092v2.294h-3.853V523.6h-2.386Z" transform="translate(-1056.065 -528.84)" fill="#f2f4ff"/>
                    <path id="Trazado_34" data-name="Trazado 34" d="M1233.828,535.187a4.366,4.366,0,0,1-4.312,2.936,4.863,4.863,0,0,1,0-9.725,4.564,4.564,0,0,1,4.587,4.679,5.228,5.228,0,0,1-.055.661l-.037.257h-6.973a2.468,2.468,0,0,0,2.478,2.2,2.139,2.139,0,0,0,1.926-1.009Zm-2.019-2.752a2.3,2.3,0,0,0-2.293-2.11,2.468,2.468,0,0,0-2.478,2.11Z" transform="translate(-1095.564 -543.24)" fill="#f2f4ff"/>
                    <path id="Trazado_35" data-name="Trazado 35" d="M1288.991,534.453a4.339,4.339,0,0,1-4.4,3.67,4.863,4.863,0,0,1,0-9.725,4.348,4.348,0,0,1,4.4,3.578H1286.7a2.138,2.138,0,0,0-2.11-1.468,2.768,2.768,0,0,0,0,5.5,2.08,2.08,0,0,0,2.11-1.56Z" transform="translate(-1140.323 -543.24)" fill="#f2f4ff"/>
                    <path id="Trazado_36" data-name="Trazado 36" d="M1335.669,510.76h2.293v4.4h.092a3.024,3.024,0,0,1,.624-.551,3.505,3.505,0,0,1,1.945-.551,3.643,3.643,0,0,1,3.67,3.945v5.6H1342v-5.413a2.018,2.018,0,0,0-4.037,0V523.6h-2.293Z" transform="translate(-1185.716 -528.841)" fill="#f2f4ff"/>
                    </g>
                </svg>
            </a>
            <div class="rrss">
                <a href="https://www.linkedin.com/company/telefonicatech" rel="noopener noreferrer" target="_blank">
                    <svg id="linkedin" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <path id="linkedin-2" data-name="linkedin" d="M1.239,2.384A1.175,1.175,0,0,1,0,1.19,1.185,1.185,0,0,1,1.271,0,1.177,1.177,0,0,1,2.524,1.19,1.188,1.188,0,0,1,1.239,2.384ZM.063,11H2.447V3.324H.063Zm8.7,0h2.385V6.464c0-2.259-1.176-3.311-2.743-3.311A2.756,2.756,0,0,0,6.037,4.394H5.989L5.881,3.327H3.812c.03.691.062,1.49.062,2.448V11H6.256V6.574a1.711,1.711,0,0,1,.079-.6,1.309,1.309,0,0,1,1.223-.9c.861,0,1.206.677,1.206,1.664Z" transform="translate(2.426 2)" fill="#f2f4ff" fill-rule="evenodd"/>
                        <rect id="fondo" width="16" height="16" fill="none"/>
                    </svg>                      
                </a>
                <a href="https://twitter.com/TelefonicaTech" rel="noopener noreferrer" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <g id="Grupo_7472" data-name="Grupo 7472" transform="translate(-53 -1400)">
                        <g id="Grupo_7471" data-name="Grupo 7471" transform="translate(55 1402)">
                            <path id="Trazado_8059" data-name="Trazado 8059" d="M1.724.934H3.135L9.55,10.078H8.139ZM.013,0l7.7,11.012H11.26L3.557,0Z" transform="translate(-0.01)" fill="#f2f4ff"/>
                            <path id="Trazado_8060" data-name="Trazado 8060" d="M0,27.158H1.125l3.966-4.594-.519-.688Z" transform="translate(0 -16.146)" fill="#f2f4ff"/>
                            <path id="Trazado_8061" data-name="Trazado 8061" d="M26.02,0,22.339,4.251l.5.71L27.122,0Z" transform="translate(-16.488 0)" fill="#f2f4ff"/>
                        </g>
                        <rect id="Rectángulo_4599" data-name="Rectángulo 4599" width="16" height="16" transform="translate(53 1400)" fill="none"/>
                        </g>
                    </svg>                                                          
                </a>
                <a href="https://www.youtube.com/c/TelefonicaTech/" rel="noopener noreferrer" target="_blank">
                    <svg id="youtube" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <path id="youtube-2" data-name="youtube" d="M12.2,1.868l.015.095A1.5,1.5,0,0,0,11.176.943l-.011,0A36.8,36.8,0,0,0,6.466.7a35.994,35.994,0,0,0-4.7.245A1.5,1.5,0,0,0,.725,1.953l0,.01a15.616,15.616,0,0,0,.016,5.77l-.016-.1a1.5,1.5,0,0,0,1.034,1.02l.01,0a36.724,36.724,0,0,0,4.7.246,36.811,36.811,0,0,0,4.7-.246,1.5,1.5,0,0,0,1.042-1.012l0-.01a14.6,14.6,0,0,0,.25-2.728c0-.035,0-.071,0-.107s0-.072,0-.111A15.063,15.063,0,0,0,12.2,1.868Zm-6.93,4.7V3.042L8.4,4.805Z" transform="translate(1.539 3.199)" fill="#f2f4ff"/>
                        <rect id="fondo" width="16" height="16" fill="none"/>
                    </svg>                                            
                </a>
                <a href="https://telefonicatech.com/contacto" rel="noopener noreferrer" target="_blank">
                    <svg id="correo" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <path id="correo-2" data-name="correo" d="M12.491,110.608a1.184,1.184,0,0,1-.239.877,1.244,1.244,0,0,1-.987.409c-.316.005-1.5.007-2.948.007-2.788,0-6.526-.007-6.762-.007a1.041,1.041,0,0,1-.7-.292,1.3,1.3,0,0,1-.352-.968v-5.78l4.9,3.089a1.854,1.854,0,0,0,1.012.311,1.713,1.713,0,0,0,.954-.3L12.5,104.83Zm-5.519-3.266a1.041,1.041,0,0,1-1.158,0L.6,104.053a1.073,1.073,0,0,1,.219-.356.98.98,0,0,1,.734-.3l9.9,0a1.03,1.03,0,0,1,.918.648Z" transform="translate(1.5 -99.65)" fill="#f2f4ff" fill-rule="evenodd"/>
                        <rect id="fondo" width="16" height="16" fill="none"/>
                    </svg>
                                                        
                </a>
            </div>
            <div class="legal-links">
                <a href="https://telefonicatech.com/aviso-legal" rel="noopener noreferrer" title={t('Aviso legal')}>{t('Aviso legal')}</a>
                <a href="https://telefonicatech.com/politica-de-privacidad" rel="noopener noreferrer" title={t('Política de privacidad')}>{t('Política de privacidad')}</a>
                <a href="https://telefonicatech.com/cookies" rel="noopener noreferrer" title={t('Cookies')}>{t('Cookies')}</a>
     
                <div class="ot-sdk-show-settings configure-cookies" onClick={openConsentDialog}>{t('Configurar/Rechazar cookies')}</div>
            </div>
        </div>          
        </footer>

    );
  }
  
export default Footer;


