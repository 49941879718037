import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import './App.sass';

import Index from './route/index';

export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/" element={<Index lang="es" />} />
          <Route path="/en" element={<Index lang="en" />} />
        </Routes>
      </Router>
    </div>
  );
}


