import  React  from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';


import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import translations_es from "./translations/es.json"
import translations_en from "./translations/en.json"

i18next.init({
    interpolation: { escapeValue: false }, 
    lng: 'en',                              
    resources: {
        en: {
            default: translations_en
        },
        es: {
            default: translations_es
        },
    },
});

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
<React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
</React.StrictMode>
);